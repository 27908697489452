import { watch } from 'vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Toolbar',

  data: () => ({
    drawer: false,
    group: null,
    logotipo: null,
    search: null,
    selectTeamObj: null,
    aplications: [
      {
        title: 'Analytics',
        icon: 'mdi-chart-timeline-variant-shimmer',
        route: 'https://analytics.adx.space/'
      },
      {
        title: 'Business',
        icon: 'mdi-bank-transfer',
        route: ''
      },
      {
        title: 'Station Score',
        icon: 'mdi-database-search-outline',
        route: 'https://stationscore.com.br/'
      },
    ],

    items: [
      {
        title: 'Campanhas',
        icon: 'mdi-format-list-text',
        items: [
          { title: 'Listar campanhas', route: '/dashboard/campaigns' },
          { title: 'Cadastrar campanha', route: '/dashboard/campaign' }
        ]
      },
      {
        title: 'Agências',
        route: 'agencies',
        icon: 'mdi-arrange-bring-to-front',
        items: [
          { title: 'Listar agências', route: '/dashboard/agencies' },
          { title: 'Cadastrar agência', route: '/dashboard/agencie' }
        ]
      },
      {
        title: 'Anunciantes',
        route: 'advertisers',
        icon: 'mdi-bookmark-box-multiple-outline',
        items: [
          { title: 'Listar anunciantes', route: '/dashboard/advertisers' },
          { title: 'Cadastrar anunciante', route: '/dashboard/advertiser' }
        ]
      },
      {
        title: 'Veículos',
        route: 'vehicles',
        icon: 'mdi-application-braces-outline',
        items: [
          { title: 'Listar veículos', route: '/dashboard/vehicles' },
          { title: 'Cadastrar veículo', route: '/dashboard/vehicle' }
        ]
      },
      {
        title: 'Usuários',
        route: 'users',
        icon: 'mdi-card-account-details-star-outline',
        items: [
          { title: 'Listar usuários', route: '/dashboard/users' },
          // { title: 'Cadastrar usuário', route: '/dashboard/campaign' }
        ]
      }
    ],
    themeModeTitle: 'Escuro'
  }),

  watch: {
    visualIdentity: {
      handler(value) {
        if (this.$vuetify.theme.dark === true) {
          this.themeModeTitle = 'Claro'
          this.logotipo = this.visualIdentity?.team_logo_negative
        } else {
          this.themeModeTitle = 'Escuro'
          this.logotipo = this.visualIdentity?.team_logo
        }
      },
      immediate: true
    },
    user: {
      handler(value) {
        const team = localStorage.getItem('team')
        if(team){
          this.selectTeamObj = JSON.parse(team)
          this.selectTeam(JSON.parse(team))
        } else{
          if(value && value?.team_id === 1){
            this.selectTeam(value?.team)
            localStorage.setItem('team', JSON.stringify(value?.team))
          }else if(value && value?.team_id != 1){
            this.getTeamData({ filter: `{ 'teams.team_id' : ${value?.team_id} }` })
          }
        }
      },
      immediate: true
    }
  },

  methods: {
    ...mapActions('auth', ['logoutUser']),
    ...mapActions('users', ['getDataTableUsers']),
    ...mapActions('dashboard', ['getVisualIdentity','selectTeam']),
    ...mapActions('dashboard', ['getAdvertisersData']),
    ...mapActions('dashboard', ['getAccountsData']),
    ...mapActions('dashboard', ['getTeamData']),
    ...mapActions('dashboard', ['getInputSearch']),

    toggleTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      if (this.$vuetify.theme.dark === true) {
        this.themeModeTitle = 'Claro'
        this.logotipo = this.visualIdentity?.team_logo_negative
      } else {
        this.themeModeTitle = 'Escuro'
        this.logotipo = this.visualIdentity?.team_logo
      }
    },

    async logout() {
      try {
        this.isLoading = true
        if (this.user) {
          await this.logoutUser()
          if (this.$route.name !== 'Login') this.$router.push({ name: 'Login' })
        }
      } catch (error) {
      } finally {
        this.isLoading = false
      }
    },

    async handlerSearchTeam(){
      if(this.search && this.user?.team_id && this.user?.team_id === 1 ){
        await this.getTeamData({ filter: `{'teams.team_name' : ${this.search} }` })
      }
    },

    async handlerSetTeam(e){
      await this.selectTeam(e)
      if(this.$route?.params?.campaign_id){
        if(this.campaign && this.campaign.team_id != e.team_id){
          this.$router.push({ name: 'campaigns' })
        }
      }
      this.drawer = !this.drawer
      localStorage.setItem('team', JSON.stringify(e))
    }
  },

  computed: {
    ...mapGetters('dashboard', ['visualIdentity']),
    ...mapGetters('auth', ['user']),
    ...mapGetters('campaign', ['campaign']),
    ...mapGetters('dashboard', ['dataTeams', 'teamSelect']),

    getTeams: {
      async get() {
        if (this.user?.team_id && this.user?.team_id === 1) {
          await this.getTeamData({
            limit: -1
          })
          await this.getAdvertisersData({
            limit: -1
          })
          await this.getAccountsData({
            limit: -1
          })
        }
      }
    },

    inputAdserver: {
      get() {},
      set(value) {
        if (value) {
          this.getDataTableUsers({
            limit: -1,
            filter: `{'team_id' : ${value.team_id} }`
          })
        } else {
          this.getDataTableUsers({
            limit: -1,
            filter: null
          })
        }
      }
    },

    inputRouter: {
      get() {},
      set(value) {
        this.getInputSearch(null)
        if (value.route !== this.$route.name) {
          this.$router.push({ name: value.route })
        }
      }
    }
  },
  async created() {
    let url = window.location.hostname
    if (
      (window.location.hostname == 'localhost' ||
      window.location.hostname == 'analytics-v4.adserver.dev' ||
      window.location.hostname === 'dev-admin-v4.00px.net')
    ) {
      url = 'https://analytics.adx.space'
    } else {
      url = window.location.hostname
    }
    await this.getVisualIdentity({ filter: null })
    const team = localStorage.getItem('team')
    this.getTeamData({ filter: "{'teams.team_active':true}" })
    if(team){
      this.selectTeam(JSON.parse(team))
      this.selectTeamObj = JSON.parse(team)
    }
  }
}
