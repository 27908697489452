import apollo from "@/plugins/apollo";
import { GET_CREATIVES_IN_CHANNEL, GET_CREATIVES_GROUPS, CREATE_CREATIVE_GROUP,UPDATE_CREATIVE_GROUP, CREATE_CREATIVE, UPDATE_CREATIVE, DELETE_CREATIVE } from "@/graphql/Dashboard/CreativesGroups/CreativesGroups";

const getCreativesGroups = async (variables) => {
  const response = await apollo.query({
    query: GET_CREATIVES_GROUPS,
    variables,
  });
  
  return response.data.campaigns;
};
const getCreativesInChannel = async (variables) => {
  const response = await apollo.query({
    query: GET_CREATIVES_IN_CHANNEL,
    variables,
  });
  
  return response.data.creatives;
};

const createCreativeGroup = async (content) => {
  let payload = { content: content }
  
  try {
    const response = await apollo.mutate({
      mutation: CREATE_CREATIVE_GROUP,
      variables: payload
    })
    return response.data.createCreativeGroup
  } catch (error) {
    console.log(error.message)
  }
}

const updateCreativeGroup = async (content) => {
  let payload = { content: content }
  
  try {
    const response = await apollo.mutate({
      mutation: UPDATE_CREATIVE_GROUP,
      variables: payload
    })
    return response.data.updateCreativeGroup
  } catch (error) {
    console.log(error.message)
  }
}

const createCreative = async (content) => {
  let payload = { content: content }
  
  try {
    const response = await apollo.mutate({
      mutation: CREATE_CREATIVE,
      variables: payload
    })
    return response.data.createCreative
  } catch (error) {
    console.log(error.message)
  }
}

const updateCreative = async (content) => {
  let payload = { content: content }
  
  try {
    const response = await apollo.mutate({
      mutation: UPDATE_CREATIVE,
      variables: payload
    })
    return response.data.updateCreative
  } catch (error) {
    console.log(error.message)
  }
}

const deleteCreative = async (content) => {
  let payload = { content: content }
  
  try {
    const response = await apollo.mutate({
      mutation: DELETE_CREATIVE,
      variables: payload
    })
    return response.data.deleteCreative
  } catch (error) {
    console.log(error.message)
  }
}
export { getCreativesInChannel, getCreativesGroups,createCreativeGroup,updateCreativeGroup, createCreative, updateCreative, deleteCreative };
