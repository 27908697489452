import apollo from '@/plugins/apollo'
import { GET_CAMPAIGNS } from '@/graphql/Dashboard/Campaigns/Campaigns'
import { CREATE_CAMPAIGN } from '@/graphql/Dashboard/Campaigns/RegisterCampaign'
import { UPDATE_CAMPAIGN } from '@/graphql/Dashboard/Campaigns/UpdateCampaign'
import { DELETE_CAMPAIGN } from '@/graphql/Dashboard/Campaigns/DeleteCampaign'
import { DELETE_CREATIVE_GROUP } from '@/graphql/Dashboard/Campaigns/DeleteCreativeGroup'
import { FIND_CAMPAIGN } from '@/graphql/Dashboard/Campaigns/Campaign'
import { COUNT_CAMPAIGNS } from '@/graphql/Dashboard/Campaigns/CountCampaign'
import { UPDATE_ADP_CAMPAIGN } from '@/graphql/Dashboard/Campaigns/UpdateAdpCampaign'
import { COPY_CAMPAIGN } from '@/graphql/Dashboard/Campaigns/CopyCampaign'

const countCampaigns = async () => {
  const response = await apollo.query({
    query: COUNT_CAMPAIGNS
  })

  return response.data.count_campaigns.count
}

const getCampaigns = async (variables) => {
  try {
    const response = await apollo.query({
      query: GET_CAMPAIGNS,
      variables
    })

    return response.data.campaigns
  } catch (error) {
    console.log(error.message)
  }
}

const createCampaign = async (content) => {
  let payload = { content: content }
  try {
    const response = await apollo.mutate({
      mutation: CREATE_CAMPAIGN,
      variables: payload
    })
    return response.data.createCampaign
  } catch (error) {
    throw {
      message: 'Algo deu errado na criação da campanha',
      error: error.message
    }
  }
}

const updateCampaign = async (content) => {
  try {
    const response = await apollo.mutate({
      mutation: UPDATE_CAMPAIGN,
      variables: content
    })
    return response.data.updateCampaign.data
  } catch (error) {
    throw {
      message: 'Algo deu errado na atualização da campanha',
      error: error.message
    }
  }
}

const copyCampaign = async (content) => {
  try {
    const response = await apollo.mutate({
      mutation: COPY_CAMPAIGN,
      variables: content
    })
    return response.data.copyCampaign.data
  } catch (error) {
    throw {
      message: 'Algo deu errado na atualização da campanha',
      error: error.message
    }
  }
}

const findCampaign = async (variables) => {
  try {
    const response = await apollo.query({
      query: FIND_CAMPAIGN,
      variables
    })
    
    return response.data.campaign
  } catch (error) {
    throw { message: 'Algo deu errado na busca da campanha', error: error.message }
  }
}

const DeleteCreativeGroup = async (variables) => {
  console.log(variables);
  try {
    const response = await apollo.mutate({
      mutation: DELETE_CREATIVE_GROUP,
      variables
    })
    
    return response.data.campaign
  } catch (error) {
    throw { message: 'Algo deu errado ao deletar a linha criativa', error: error.message }
  }
}

const updateAdpCampaign = async (content) => {
  try {
    const response = await apollo.mutate({
      mutation: UPDATE_ADP_CAMPAIGN,
      variables: content
    })
    return response.data
  } catch (error) {
    throw {
      message: 'Algo deu errado na atualização do adp da campanha',
      error: error.message
    }
  }
}

export {
  getCampaigns,
  createCampaign,
  findCampaign,
  updateCampaign,
  updateAdpCampaign,
  countCampaigns,
  DeleteCreativeGroup,
  copyCampaign
}
